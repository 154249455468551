<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalCreateOrUpdatePresale"
      @click:outside="executeCloseModalCreateOrUpdatePresale"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            {{
              currentPresale
                ? "Actualizar Proyecto de Preventa"
                : "Crear Proyecto de Preventa"
            }}
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-form @submit.prevent="sendUpdateOrCreatePresale">
              <v-row align-content="center" class="mt-2">
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="name_project"> Nombre del Proyecto * </label>
                  <v-text-field
                    placeholder="Ingresa el Nombre del Proyecto"
                    v-model="$v.form.name_project.$model"
                    :error="$v.form.name_project.$invalid && submitUpload"
                    :error-messages="
                      $v.form.name_project.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="presale_code"> Código de la Preventa * </label>
                  <v-text-field
                    placeholder="Ingresa el Código del Proyecto"
                    v-model="$v.form.presale_code.$model"
                    :error="$v.form.presale_code.$invalid && submitUpload"
                    :error-messages="
                      $v.form.presale_code.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label class="mt-1"> Unidad de Negocio * </label>
                  <v-autocomplete
                    placeholder="Selecciona la Unidad de Negocio"
                    v-model="$v.form.business_unit.$model"
                    :error="$v.form.business_unit.$invalid && submitUpload"
                    :error-messages="
                      $v.form.business_unit.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="activeBusinessUnits"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalCreateOrUpdatePresale"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            @click="sendUpdateOrCreatePresale"
            :color="`${currentPresale ? 'warning' : 'primary'} text-white`"
            small
            class="float-right"
          >
            {{
              currentPresale
                ? "Actualizar Proyecto de Preventa"
                : "Crear Proyecto de Preventa"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
import { ACTIVE_BUSINESS_UNITS } from "@/constants/presale";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateOrUpdatePresale: {
      type: Function,
      default: () => {},
    },
    currentPresale: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        name_project: null,
        presale_code: null,
        business_unit: "RE",
      },
      submitUpload: false,
    };
  },
  validations: {
    form: {
      name_project: { required },
      presale_code: {
        required,
      },
      business_unit: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      updateOrCreatePresale: "presale/updateOrCreatePresale",
    }),
    async sendUpdateOrCreatePresale() {
      let self = this;
      self.submitUpload = true;
      if (!self.$v.form.$invalid) {
        self.loading = true;
        const resp = await self.updateOrCreatePresale({
          id: self.currentPresale ? self.currentPresale.id : undefined,
          created_by: self.user.email,
          name: self.form.name_project,
          code: self.form.presale_code,
          business_unit_id: self.form.business_unit,
          comment: self.form.name_project,
        });
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: !self.currentPresale
              ? "Proyecto de Preventa creado correctamente"
              : "Proyecto de Preventa actualizado correctamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("presaleCreatedOrUpdated");
          this.closeModalCreateOrUpdatePresale(true);
          this.resetForm();
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    executeCloseModalCreateOrUpdatePresale($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalCreateOrUpdatePresale($event);
      }
    },
    setPresale(presale) {
      this.form = {
        name_project: presale.name,
        presale_code: presale.code,
        business_unit: presale.business_unit.id,
      };
    },
    resetForm() {
      this.form = {
        name_project: null,
        presale_code: null,
        business_unit: null,
      };
      this.submitUpload = false;
    },
    setBusinessUnitDefault() {
      this.form.business_unit = this.businessUnits.find(
        (item) => item.code === "RE"
      ).id;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      businessUnits: "common/businessUnits",
    }),
    activeBusinessUnits() {
      return this.businessUnits.filter((item) =>
        ACTIVE_BUSINESS_UNITS.includes(item.name)
      );
    },
  },
  watch: {
    currentPresale(newForm) {
      if (newForm) {
        this.setPresale(newForm);
      } else {
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
