<template>
  <section class="px-16 mt-8">
    <loading-flux :value="loadingActions"></loading-flux>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <section class="d-flex flex-column flex-sm-row justify-content-between">
          <h2 class="my-3">Proyectos de Preventa</h2>
        </section>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-3">
        <v-btn
          @click="openModalUpdateOrCreatePresale(null)"
          :disabled="loading"
          color="primary"
          :dark="false"
          small
          class="text-white mt-4"
        >
          <i class="mdi mdi-plus me-1"></i>
          Crear Proyecto de Preventa
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" class="mb-2">
        <v-text-field
          label="Búsqueda por Código Preventa"
          @input="($event) => getPresalesData()"
          v-model="filters.filter"
          clearable
          class="mt-3"
          placeholder="Busqueda"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="5" md="5" sm="12" class="mb-2">
        <v-autocomplete
          class="mt-3"
          placeholder="Filtrar por Unidad de Negocio"
          v-model="filters.business_unit"
          :items="activeBusinessUnits"
          @change="($event) => getPresalesData()"
          item-text="name"
          item-value="code"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" class="mb-2">
        <v-autocomplete
          v-model="filters.items"
          class="mt-3"
          @change="($event) => getPresalesData()"
          :items="[5, 10, 15, 20, 25]"
          label="Items por página"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="mb-2">
        <v-data-table
          :page.sync="filters.page"
          :headers="wrappedTableHeaders"
          :items="presales.items ? presales.items : []"
          item-key="code"
          :items-per-page="filters.items"
          class="elevation-1"
          mobile-breakpoint="993"
          hide-default-footer
          :loading="loading"
          no-data-text="No hay Datos para Mostrar"
          loading-text="Cargando Preventas... Espere Por favor"
        >
          <template v-slot:item.name="{ item }">
            <div
              class="link-text text-left"
              @click="showPresaleDocuments(item)"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.business_unit="{ item }">
            <v-chip pill color="primary" class="text-white">{{
              item.business_unit.name
            }}</v-chip>
          </template>
          <template v-slot:item.created_at="{ item }">
            <span v-if="item.created_at != null">{{
              formatDateToHuman(item.created_at)
            }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="success"
                  small
                  icon
                  class="text-white mt-1"
                  @click="showPresaleDocuments(item)"
                >
                  <v-icon> mdi-folder </v-icon>
                </v-btn>
              </template>
              <span>Ver Documentos</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="warning"
                  small
                  icon
                  class="text-white mt-1 mx-2"
                  @click="openModalUpdateOrCreatePresale(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="red darken-2"
                  small
                  icon
                  class="text-white"
                  @click="deletePresale(item)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="filters.page"
            :length="presales ? presales.total_pages : 1"
            :total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <modal-update-or-create-presale
      ref="modalUpdateOrCreatePresale"
      :currentPresale="currentPresale"
      :modalAction="dialogCreatePresale"
      :closeModalCreateOrUpdatePresale="closeModalCreateOrUpdatePresale"
      @presaleCreatedOrUpdated="getPresalesData(1)"
    >
    </modal-update-or-create-presale>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDateMomentToHuman } from "@/helpers/common";
import ModalUpdateOrCreatePresale from "./components/ModalUpdateOrCreatePresale.vue";
import roles from "@/mixins/roles";
import { ACTIVE_BUSINESS_UNITS } from "@/constants/presale";
export default {
  mixins: [roles],
  components: { ModalUpdateOrCreatePresale },
  data() {
    return {
      dialogCreatePresale: false,
      currentPresale: null,
      loading: false,
      loadingActions: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      tableHeaders: [
        {
          value: "code",
          text: "Código de Preventa",
          sortable: true,
          align: "center",
          width: "10%",
        },
        {
          value: "name",
          text: "Nombre del Proyecto",
          sortable: true,
          width: "30%",
        },
        {
          value: "business_unit",
          text: "Unidad de Negocio",
          sortable: true,
          align: "center",
          width: "10%",
        },
        {
          value: "created_at",
          sortable: true,
          align: "center",
          text: "Fecha Creación",
          width: "10%",
        },
      ],
    };
  },
  mounted() {
    this.getPresalesData();
  },
  methods: {
    ...mapActions({
      getAllPresales: "presale/getAllPresales",
      deletePresaleService: "presale/deletePresale",
    }),
    async getPresalesData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = await setTimeout(async function () {
        self.loading = true;
        self.filters.page = page;
        await self.getAllPresales({
          page,
          business_unit_filter: self.filters.business_unit
            ? self.filters.business_unit
            : undefined,
          code_filter: self.filters.filter ? self.filters.filter : undefined,
          items: self.filters.items,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    async showPresaleDocuments(item) {
      this.$router.push(
        "/preventa/" + item.code + "/documentos?presale_code=" + item.id
      );
    },
    formatDateToHuman(date) {
      return formatDateMomentToHuman(date);
    },
    openModalUpdateOrCreatePresale(presale) {
      if (!presale) {
        this.$refs.modalUpdateOrCreatePresale.setBusinessUnitDefault();
      }
      this.currentPresale = presale;
      this.dialogCreatePresale = true;
    },
    closeModalCreateOrUpdatePresale() {
      this.currentPresale = null;
      this.dialogCreatePresale = false;
    },
    deletePresale(presale) {
      this.$swal
        .fire({
          icon: "warning",
          title:
            "¿Estás seguro de eliminar el Proyecto de Preventa con código: " +
            "<b>" +
            presale.code +
            "</b>" +
            "?",
          text: "Esta acción no se puede deshacer",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "red",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            this.loadingActions = true;
            const resp = await this.deletePresaleService({
              preventa_id: presale.id,
              user: this.user.email,
              comment: "Eliminado por " + this.user.email,
            });
            if (resp.status == 200) {
              this.$swal.fire({
                icon: "success",
                title: "Excelente",
                text:
                  "El Proyecto de Preventa con código: " +
                  "<b>" +
                  presale.code +
                  "</b>" +
                  " ha sido eliminado",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 5000,
                timerProgressBar: true,
              });
              this.getPresalesData();
            }
            if (!resp.status || resp.status === 400) {
              this.loading = false;
              return this.$swal.fire({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 10000,
                timerProgressBar: true,
              });
            }
            this.loadingActions = false;
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      presales: "presale/presales",
      businessUnits: "common/businessUnits",
    }),
    wrappedTableHeaders() {
      if (this.currentUserIsSuperAdmin) {
        return [
          ...this.tableHeaders,
          {
            value: "actions",
            text: "Acciones",
            sortable: false,
            align: "center",
            width: "10%",
          },
        ];
      }
      return this.tableHeaders;
    },
    activeBusinessUnits() {
      return this.businessUnits.filter((item) =>
        ACTIVE_BUSINESS_UNITS.includes(item.name)
      );
    },
  },
};
</script>

<style></style>
