import { authUsuarioComputed } from "@/state/helpers";

export default {
    data(){
        return {
            roles: {
                super_admin: 'SUPER USUARIO',
            },
        }
    },
    computed: {
        ...authUsuarioComputed,
        currentUserIsSuperAdmin(){
            return this.user && this.user.nombre_rol === this.roles.super_admin
        }
    }
}